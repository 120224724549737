import React from "react";
import Layout from "../../../other/layout";
import Promotions from "../../../components/AC-StaticPages/sales-resources/promotions/Promotions";
import SEO from "../../../other/seo";

export default function promotions() {
  return (
    <Layout>
      <SEO title="Promotions" />

      <Promotions />
    </Layout>
  );
}
