import React from "react";
import { Link } from "gatsby";
import SalesMaterials_Hero from "../../../../assets/img/T0_Resources-SalesTools.jpg";
import product_sell_sheets from "../../../../assets/img/Product_sell_sheet.jpg";
import catalogues from "../../../../assets/img/Catalogues.jpg";

import styles from "../../style/styles.module.css";
import { LINK_DISTRIBUTION } from "../../../../project-config";

export default function Promotions() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.content}>
          <div className={styles.bannerWrapper}>
            <img
              src={
                LINK_DISTRIBUTION +
                "/" +
                "store/20090722001/assets/templateimages/Current_promotions.jpg"
              }
              alt="Current Promotions"
            />

            <div className={styles.banner}>
              <h2>
                15% off plus Free Shipping on New Standard Cheques and Forms
              </h2>
              <div className={styles.bannerContent}>
                <strong>Offer expires:</strong> December 31, 2023<br></br>
                <strong>Valid:</strong> Online / Phone<br></br>
                <strong>How to get:</strong> Enter promotion code{" "}
                <strong>KJ808 </strong> at check out.
                <div className={styles.buttonsWrapper}>
                  <Link
                    to="/products/business-cheques/"
                    className={styles.linkButton}
                  >
                    Shop Cheques
                  </Link>
                  <Link
                    to="/products/business-forms/"
                    className={styles.linkButton}
                  >
                    Shop Forms
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <h1>Current Promotions</h1>
          <p className={styles.mainP}>
            Rules & Restrictions:
            <br />
            Applies to new standard cheques and form orders. Includes orders for
            new customer or orders that are new to your existing customer.
            Excludes reorders, specialty orders, EZShield, personal cheques,
            business services, starter kits, promotional, seasonal and print
            marketing materials. Not valid in combination with any other offer
            or on previously placed orders.
          </p>
        </div>
      </div>
    </div>
  );
}
